// @flow
import { all, call, fork, put, select, takeEvery } from 'redux-saga/effects';
import { SmsSendingsActionTypes } from './constants';
import {
    createSmsSending as createSmsSendingApi,
    getAboutSmsSending as getAboutSmsSendingApi,
    getSmsSendings as getSmsSendingsApi,
    patchSmsSending as patchSmsSendingApi,
    sendSendingToArchive as sendSendingToArchiveApi,
    updateSmsSending as updateSmsSendingApi,
    updateStatus as updateStatusApi,
} from '../../helpers/';
import {
    getSmsSendings as getSmsSendingsAction,
    setSending,
    smsApiResponseError,
    smsApiResponseSuccess,
} from './actions';
import { statuses } from '../../pages/smska/sms/constants';
import { fromTimestampStringToDateString } from '../../utils/moment';
import { addNotification } from '../notifications/actions';

function* getSendings(action) {
    try {
        const response = yield call(getSmsSendingsApi, action.payload);
        const { sendingList, totalCount } = response.data.sms;
        sendingList.forEach((sendingItem) => {
            sendingItem.status = statuses[sendingItem.status];
            sendingItem.date_start = fromTimestampStringToDateString(sendingItem.date_start);
            sendingItem.date_end = fromTimestampStringToDateString(sendingItem.date_end);
        });
        const pageCount = Math.ceil(totalCount / action.payload?.limit);
        yield put(smsApiResponseSuccess(SmsSendingsActionTypes.GET_SENDINGS, { sendingsList: sendingList, pageCount }));
    } catch (error) {
        yield put(smsApiResponseError(SmsSendingsActionTypes.GET_SENDINGS, error));
    }
}

function* getAboutSending(action) {
    try {
        const response = yield call(getAboutSmsSendingApi, action.payload);
        const sending = response.data.aboutSmsSending;
        yield put(setSending(sending));
        yield put(smsApiResponseSuccess(SmsSendingsActionTypes.GET_ABOUT_SENDING, null));
    } catch (error) {
        yield put(smsApiResponseError(SmsSendingsActionTypes.GET_ABOUT_SENDING, error));
    }
}

function* sendSendingToArchive(action) {
    try {
        yield call(sendSendingToArchiveApi, action.payload);
        yield put(smsApiResponseSuccess(SmsSendingsActionTypes.SEND_TO_ARCHIVE, action.payload));
        yield put(
            addNotification({
                message: 'Рассылка отправлена в архив',
                variant: 'success',
            })
        );
    } catch (error) {
        yield put(smsApiResponseError(SmsSendingsActionTypes.SEND_TO_ARCHIVE, error));
        yield put(
            addNotification({
                message: 'Не удалось отправить рассылку в архив',
                variant: 'danger',
            })
        );
    }
}

function* updateStatus(action) {
    try {
        const result = yield call(updateStatusApi, action.payload);
        yield put(smsApiResponseSuccess(SmsSendingsActionTypes.UPDATE_STATUS, result.data.response));
    } catch (error) {
        yield put(smsApiResponseError(SmsSendingsActionTypes.UPDATE_STATUS, error));
        yield put(
            addNotification({
                message: 'Не удалось изменить статус',
                variant: 'danger',
            })
        );
    }
}

function* createSmsSending(action) {
    try {
        yield call(createSmsSendingApi, action.payload);
        yield put(smsApiResponseSuccess(SmsSendingsActionTypes.CREATE_SENDING));
        yield put(
            addNotification({
                message: 'Новая рассылка создана',
                variant: 'success',
            })
        );
    } catch (error) {
        yield put(smsApiResponseError(SmsSendingsActionTypes.CREATE_SENDING, error));
        yield put(
            addNotification({
                message: 'Не удалось создать рассылку',
                variant: 'danger',
            })
        );
    }
}

function* updateSmsSending(action) {
    try {
        yield call(updateSmsSendingApi, action.payload);
        yield put(smsApiResponseSuccess(SmsSendingsActionTypes.UPDATE_SENDING));
        yield put(
            addNotification({
                message: 'Рассылка изменена',
                variant: 'success',
            })
        );
    } catch (error) {
        yield put(smsApiResponseError(SmsSendingsActionTypes.UPDATE_SENDING, error));
        yield put(
            addNotification({
                message: 'Не удалось изменить рассылку',
                variant: 'danger',
            })
        );
    }
}

function* renameSmsSending(action) {
    try {
        const response = yield call(patchSmsSendingApi, action.payload);
        yield put(smsApiResponseSuccess(SmsSendingsActionTypes.RENAME_SENDING));
        if (response.data.status === 'OK') {
            const filter = yield select((state) => state.SmsSendings.smsSendingFilter);
            yield put(getSmsSendingsAction(filter));
            yield put(
                addNotification({
                    message: 'Рассылка переименована',
                    variant: 'success',
                })
            );
        }
    } catch (error) {
        yield put(smsApiResponseError(SmsSendingsActionTypes.RENAME_SENDING, error));
        yield put(
            addNotification({
                message: 'Не удалось переименовать рассылку',
                variant: 'danger',
            })
        );
    }
}

function* changeAgentInSending(action) {
    try {
        const response = yield call(patchSmsSendingApi, action.payload);
        yield put(smsApiResponseSuccess(SmsSendingsActionTypes.CHANGE_AGENT));
        if (response.data.status === 'OK') {
            const filter = yield select((state) => state.SmsSendings.smsSendingFilter);
            yield put(getSmsSendingsAction(filter));
            yield put(
                addNotification({
                    message: 'Агент рассылки изменён',
                    variant: 'success',
                })
            );
        }
    } catch (error) {
        yield put(smsApiResponseError(SmsSendingsActionTypes.CHANGE_AGENT, error));
        yield put(
            addNotification({
                message: 'Не удалось изменить агента рассылки',
                variant: 'danger',
            })
        );
    }
}

export function* watchGetSendings(): any {
    yield takeEvery(SmsSendingsActionTypes.GET_SENDINGS, getSendings);
}

export function* watchSendSendingToArchive(): any {
    yield takeEvery(SmsSendingsActionTypes.SEND_TO_ARCHIVE, sendSendingToArchive);
}

export function* watchGetAboutSending(): any {
    yield takeEvery(SmsSendingsActionTypes.GET_ABOUT_SENDING, getAboutSending);
}

export function* watchUpdateStatus(): any {
    yield takeEvery(SmsSendingsActionTypes.UPDATE_STATUS, updateStatus);
}

export function* watchCreateSmsSending(): any {
    yield takeEvery(SmsSendingsActionTypes.CREATE_SENDING, createSmsSending);
}

export function* watchUpdateSmsSending(): any {
    yield takeEvery(SmsSendingsActionTypes.UPDATE_SENDING, updateSmsSending);
}

export function* watchRenameSmsSending(): any {
    yield takeEvery(SmsSendingsActionTypes.RENAME_SENDING, renameSmsSending);
}

export function* watchChangeAgentInSending(): any {
    yield takeEvery(SmsSendingsActionTypes.CHANGE_AGENT, changeAgentInSending);
}

function* smsSendingsSaga(): any {
    yield all([
        fork(watchGetSendings),
        fork(watchUpdateStatus),
        fork(watchCreateSmsSending),
        fork(watchUpdateSmsSending),
        fork(watchGetAboutSending),
        fork(watchSendSendingToArchive),
        fork(watchRenameSmsSending),
        fork(watchChangeAgentInSending),
    ]);
}

export default smsSendingsSaga;
