import { APICore } from './apiCore';

const api = new APICore();
const baseUrl = '/api/v1/bases/';

function getBases(params: any, signal): any {
    let requestParams = params;
    if (params?.filter?.date) {
        const [from, to] = params?.filter?.date;
        requestParams = {
            ...params,
            filter: {
                ...params.filter,
                date: { from, to },
            },
        };
    }
    return api.getWithFilter(`${baseUrl}`, requestParams, signal);
}

function createBase(params: any): any {
    return api.createWithFile(`${baseUrl}`, params);
}

function renameBase(params: any): any {
    return api.updatePatch(`${baseUrl}`, params);
}

export { getBases, createBase, renameBase };
